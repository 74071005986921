<template>
	<div class="p-2">
        <div class="row pl-3">
            <div class="form-group">
                <label class="pr-2"> Compteur : </label>
                <select v-model="selectedADCO" @change="meterChanged">
                    <option v-for="meter in meters.meters" v-bind:key="meter.id">
                        {{meter.ADCO}}
                    </option>
                </select>
            </div>
            <meter-data class="pl-5"></meter-data>
        </div>
        
        <div class="row d-flex justify-content-center">
            <intensity-chart class="col-10 pl-5"></intensity-chart>
        </div>
        <div class="row d-flex justify-content-center">
            <index-chart class="col-10 pl-5"></index-chart>  
        </div>  
    </div>
</template>

<script>
import MeterData from '@/components/MeterData';
import IntensityChart from '@/components/charts/IntensityChart';
import IndexChart from '@/components/charts/IndexChart';

import {EventBus} from '../main.js';

const axios = require('axios');
const Api = require('@/services/Api.js');

export default {
	components: {
        MeterData,
        IntensityChart,
        IndexChart
	},
	data () {
		return {
            selectedADCO: null,
			meters: []
		}
	},
	mounted () {
        this.loadAllMeters();
	},
	methods: {
        
        loadAllMeters: async function()
        {
            try
            {
                const options =  {
                    headers: {
                        'authorization': 'Basic ' + btoa(this.$store.state.login + ':' + this.$store.state.password)
                    }
                };

                const response = await axios.get(Api.BASE_URL + '/meters', options);
                this.meters = response.data;
            }
            catch(error)
            {
                console.log(error);
            }
        },

        meterChanged: function()
        {
            EventBus.$emit('meterChanged', this.selectedADCO);
        }
	}
}
</script>