<template>
    <div class="container-fluid">
    <div class="row mt-xl-5">
        <div class="offset-xl-3 col-xl-2 text-center">
            <img src="img/fail.svg" style="height: 200px">
        </div>
        <div class="col-xl-4 text-center">
            <div class="text-xl-left">
                <h1 class="font-weight-bolder h1" style="font-size: 115px;">404</h1>
                <h4>Essaie encore !</h4>
                La page demandée n'existe pas...
                <div class="mt-5 border-top pt-3">
                    <a href="/" class="btn btn-primary">Retour à la maison</a>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
    export default {
        components: {  },
        data() {
            return {
            }
        },
        methods: {
        },
        mounted() {
        }
    }
</script>
