<template>
    <div id="app">
		<Menu></Menu>
		<main class="content container-fluid">
			<transition name="fade" mode="in-out">
				<router-view></router-view>
			</transition>
		</main>
    </div>
</template>

<script>
import Menu from '@/components/Menu';

export default {
	name: 'App',
	components: {
		Menu
	}
}
</script>