<template>
    <div>
        <nav class="navbar navbar-dark bg-primary justify-content-between">
            <a class="navbar-brand">EDF_TeleInfo_API</a>
        </nav>
    </div>
</template>

<script>

export default {
    
}
</script>