import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({

    strict: false,
    state:{
        login: null,
        password: null,
        isUserLoggedIn: null
    },
    mutations:{
        setLogin(state, login)
        {
            state.login = login;
        },
        setPassword(state, password)
        {
            state.password = password;
        },
        setIsUserLoggedIn(state, isUserLoggedIn)
        {
            state.isUserLoggedIn = isUserLoggedIn;
        }
    },
    actions:{
        setLogin({commit}, login)
        {
            commit('setLogin', login);
        },
        setPassword({commit}, password)
        {
            commit('setPassword', password);
        },
        setIsUserLoggedIn({commit}, isUserLoggedIn)
        {
            commit('setIsUserLoggedIn', isUserLoggedIn);
        }
    }
})