<template>
	<div style="" v-if="dataReady">
		<line-chart :chart-data="datacollection" :options="options"></line-chart>
	</div>
</template>

<script>
import LineChart from './LineChart.js';
import {EventBus} from "../../main.js";
import moment from 'moment';

const Api = require('@/services/Api.js');
const axios = require('axios');

export default {
	components: {
		LineChart
	},
	data () {
		return {
			adco: String,
			datacollection: null,
			options: null,
			intensities: [],
			dataReady: false
		}
	},
	mounted () {

		EventBus.$on('meterChanged', adco =>{
            this.adco = adco;
            this.loadIntensities();
		});
		
		this.options = {
			maintainAspectRatio: false,
			responsive: true
		};
	},
	methods: {
		
		loadIntensities: async function()
		{
			this.dataReady = false;

			try
            {
				const options =  {
                    headers: {
                        'authorization': 'Basic ' + btoa(this.$store.state.login + ':' + this.$store.state.password)
                    }
                };

                const uri = '/meters/' + this.adco + '/intensities?limit=120';
				
				const response = await axios.get(Api.BASE_URL + uri, options);

				this.intensities = response.data.intensities.reverse();

				this.updateData();
            }
            catch(error)
            {
                console.log(error);
            }
		},

		updateData: function()
		{
			let labels = [], data1 = [], data2 = [], data3 = [];
			let i;

			for(i = 0; i < this.intensities.length; i++)
			{
				let label = moment(this.intensities[i].createdAt).format("DD-MM-YYYY HH:mm")
				labels.push(label);
				data1.push(this.intensities[i].IINST1);
				data2.push(this.intensities[i].IINST2);
				data3.push(this.intensities[i].IINST3);
			}

			this.datacollection = {
				labels: labels,
				datasets: [
					{
						label: 'IINTS1',
						borderColor: '#f87979',
						data: data1,
						fill: false
					},
					{
						label: 'IINTS2',
						borderColor: '#3a963a',
						data: data2, 
						fill: false
					},
					{
						label: 'IINTS3',
						borderColor: '#4472b9',
						data: data3, 
						fill: false
					}
				]
			};

			this.dataReady = true;
		}
	}
}
</script>