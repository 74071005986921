<template>
    <div class="row justify-content-center">
        <div class="card mt-3 col-md-6">
            <div class="card-header mt-3">
                Connexion
            </div>
            <div class="card-body" v-on:keyup.enter="login">
                <form name="login-form" autocomplete="off">
                    <div class="form-group">
                        <label for="username">Utilisateur</label>
                        <input type="text" class="form-control" id="username" placeholder="Utilisateur" v-model="user" @change="clearErrorMessage">
                    </div>
                    <div class="form-group">
                        <label for="password">Mot de passe</label>
                        <input type="password" class="form-control" id="password" placeholder="Mot de passe" v-model="password" @change="clearErrorMessage">
                    </div>
                </form>
                <div class="alert alert-info" role="alert" v-if="showErrorMessage">
                    {{errorMessage}}
                </div>
                <div class="row justify-content-center">
                    <button class="btn btn-primary m-1" v-on:click="login">Se connecter</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const axios = require('axios');
const Api = require('@/services/Api.js');

export default 
{
    components: {},
    data() {
        return {
            user: '',
            password: '',
            showErrorMessage: false,
            errorMessage: ''
        }
    },
    methods: {
        login: async function()
        {
            const credentials = this.user + ':' + this.password
            
            try 
            {
                const options =  {
                    headers: {
                        'authorization': 'Basic ' + btoa(credentials)
                    }
                };
                
                await axios.post(Api.BASE_URL + '/login', {}, options);

                this.$store.dispatch('setIsUserLoggedIn', true);
                this.$store.dispatch('setLogin', this.user);
                this.$store.dispatch('setPassword', this.password);
                
                this.$router.push({ name: 'home', params: { }});
            } 
            catch (error) 
            {
                this.errorMessage  = error;//.response.data.error;
                this.showErrorMessage = true;
            }            
        },
        clearErrorMessage: function()
        {
            this.showErrorMessage = false;
        }
    }
}
</script>