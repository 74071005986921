import Vue from 'vue'
import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.css';

import store from './store/index.js';
import router from './router/index.js';
import {sync} from 'vuex-router-sync';

sync(store, router);

Vue.config.productionTip = false;

export const EventBus = new Vue();

new Vue({
  render: h => h(App),
  store, 
  router: router
}).$mount('#app');
