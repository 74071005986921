import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index.js';

import Home from '@/pages/Home';
import Login from '@/pages/Login';
import NotFound from '@/pages/NotFound';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '*',
        component: NotFound
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) =>
{
    if(!store.state.isUserLoggedIn && to.name != 'login')
    {
        next({name: 'login'});
    }
    else
    {
        next();
    }
});

export default router;