<template>
	<div style="" v-if="dataReady">
		<bar-chart :chart-data="datacollection" :options="options"></bar-chart>
	</div>
</template>

<script>
import BarChart from './BarChart.js';
import {EventBus} from "../../main.js";
import moment from 'moment';

const Api = require('@/services/Api.js');
const axios = require('axios');

export default {
	components: {
		BarChart
	},
	data () {
		return {
			adco: String,
			datacollection: null,
			options: null,
			indextempos: [],
			dataReady: false
		}
	},
	mounted () {

		EventBus.$on('meterChanged', adco =>{
            this.adco = adco;
            this.loadIntensities();
		});
		
		this.options = {
			maintainAspectRatio: false,
			responsive: true
		};
	},
	methods: {
		
		loadIntensities: async function()
		{
			this.dataReady = false;
			
			try
            {
                const uri = '/meters/' + this.adco + '/indextempos';
				
				const options =  {
                    headers: {
                        'authorization': 'Basic ' + btoa(this.$store.state.login + ':' + this.$store.state.password)
                    }
                };

				const response = await axios.get(Api.BASE_URL + uri, options);
                
				this.indextempos = response.data.indextempos.reverse();

				this.updateData();
            }
            catch(error)
            {
                console.log(error);
            }
		},

		updateData: function()
		{
			let labels = [], hcb = [], hpb = [], hcw = [], hpw = [], hcr = [], hpr = [];
			let i;

			for(i = 1; i < this.indextempos.length; i++)
			{
				let label = moment(this.indextempos[i - 1].createdAt).format("DD-MM-YYYY");
				labels.push(label);
				hcb.push((this.indextempos[i].BBRHCJB - this.indextempos[i - 1].BBRHCJB)/1000);
				hpb.push((this.indextempos[i].BBRHPJB - this.indextempos[i - 1].BBRHPJB)/1000);
				hcw.push((this.indextempos[i].BBRHCJW - this.indextempos[i - 1].BBRHCJW)/1000);
				hpw.push((this.indextempos[i].BBRHPJW - this.indextempos[i - 1].BBRHPJW)/1000);
				hcr.push((this.indextempos[i].BBRHCJR - this.indextempos[i - 1].BBRHCJR)/1000);
				hpr.push((this.indextempos[i].BBRHPJR - this.indextempos[i - 1].BBRHPJR)/1000);
			}

			this.datacollection = {
				labels: labels,
				datasets: [
					{
						label: 'HC_Bleu',
						backgroundColor: '#1459c4',
						data: hcb,
						fill: false
					},
					{
						label: 'HP_Bleu',
						backgroundColor: '#007ce4',
						data: hpb,
						fill: false
                    },
					{
						label: 'HC_Blanc',
						backgroundColor: '#c6c6c6',
						data: hcw,
						fill: false
                    },
					{
						label: 'HP_Blanc',
						backgroundColor: '#a2a2a2',
						data: hpw,
						fill: false
                    },
					{
						label: 'HC_Rouge',
						backgroundColor: '#cd2c31',
						data: hcr,
						fill: false
                    },
					{
						label: 'HP_Rouge',
						backgroundColor: '#cd0006',
						data: hpr,
						fill: false
                    }
				]
			};

			this.dataReady = true;
		}
	}
}
</script>