<template>
    <div class="row form-group">
        <p> Intensité souscrite : {{meter.ISOUSC}} </p>
        <p class="pl-2"> Option tarifaire : {{meter.OPTARIF}} </p>
    </div>
</template>

<script>
import {EventBus} from '../main.js';

const Api = require('@/services/Api.js');
const axios = require('axios');

export default {
	components: {
		
    },
	data () {
		return {
            meter: Object,
            adco: String
		}
	},
	mounted () {
        
        EventBus.$on('meterChanged', adco =>{
            this.adco = adco;
            this.loadMeter();
        });
	},
	methods: {
        
        loadMeter: async function()
        {
            try
            {
                const uri = '/meters/' + this.adco;

                const options =  {
                    headers: {
                        'authorization': 'Basic ' + btoa(this.$store.state.login + ':' + this.$store.state.password)
                    }
                };

                const response = await axios.get(Api.BASE_URL + uri, options);
                
                this.meter = response.data.meter;
            }
            catch(error)
            {
                console.log(error);
            }
        }
	}
}
</script>